<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 pt-5 pb-0">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t('CITIES.CITIES') }}
        </h3>
        <div class="card-toolbar">
          <!--begin::Button-->
          <router-link
            to="/cities/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Home/Building.svg" /> </span
            >{{ $t('CITIES.ADD_NEW_CITY') }}
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div v-if="!isLoading" class="table-responsive mb-0 pb-0">
          <table
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="text-uppercase">
                <th class="pl-6">
                  <span>
                    <span
                      class="pt-2"
                      :class="{ 'text-primary': nameOrder.isActive }"
                    >
                      {{ $t('CITIES.CITY_NAME') }}</span
                    >
                    <span
                      class="svg-icon svg-icon-sm pl-2"
                      :class="{
                        'svg-icon-primary': nameOrder.isActive,
                      }"
                    >
                      <inline-svg
                        v-if="nameOrder.query === 'asc'"
                        src="media/svg/icons/Navigation/Down-2.svg"
                      />
                      <inline-svg
                        v-else
                        src="media/svg/icons/Navigation/Up-2.svg"
                      />
                    </span>
                  </span>
                </th>
                <th class="text-right">{{ $t('GENERAL.LOCATIONS_NUMBER') }}</th>
                <th class="text-right pr-6">{{ $t('GENERAL.ACTIONS') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(city, key) in cities" :key="city.id">
                <td :class="{ 'border-top-0': key === 0 }" class="pl-6">
                  <router-link
                    :to="'/cities/edit/' + city.id"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                    >{{ city.name }}</router-link
                  >
                </td>
                <td :class="{ 'border-top-0': key === 0 }" class="text-right">
                  <span
                    class="label label-lg label-inline label-light-primary"
                    >{{ city.locations.length }}</span
                  >
                </td>
                <td
                  :class="{ 'border-top-0': key === 0 }"
                  class="text-right pl-6"
                >
                  <button
                    type="button"
                    @click.prevent="toggleViewCitiesModal(key)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/Home/Building.svg" />
                    </span>
                  </button>
                  <router-link
                    :to="'/cities/edit/' + city.id"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg
                        src="media/svg/icons/General/Settings-1.svg"
                      />
                    </span>
                  </router-link>
                  <button
                    type="button"
                    @click.prevent="deleteEntity(city.id)"
                    class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                  >
                    <span class="svg-icon svg-icon-md">
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="col-12 text-center pb-5">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
         <div
                class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
              >
                <b-pagination-nav
                  :link-gen="linkGen"
                  :number-of-pages="pagination.totalPages"
                  use-router
                  size="lg"
                ></b-pagination-nav>
              </div>
      </div>
      
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal id="city_locations" size="md" hide-header hide-footer centered>
          <div v-if="selectedLocations">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('city_locations')"
              >{{ $t('GENERAL.CLOSE') }}</b-button
            >
            <h3>{{ selectedLocations.name }}</h3>
            <ul class="mt-5">
              <li
                v-for="(location, key) in selectedLocations.locations"
                :key="key"
              >
                {{ location.title }}
              </li>
            </ul>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  name: 'CitiesList',
  data() {
    return {
      cities: [],
      selectedLocations: null,
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
      pagination: {
        totalPages: 0,
        totalItems: 0,      // Added this to handle total items
        currentPage: 1,
        itemsPerPage: 30,
      },
      isLoading: true,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Cities', route: '' }]);
    this.pagination.currentPage = this.$route.query.page || 1;
    this.getCities();
  },
  watch: {
    // Watch for page changes in the URL
    '$route.query.page'(newPage) {
      this.pagination.currentPage = newPage || 1;
      this.getCities();
    }
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    getCities() {
      this.isLoading = true;

      ApiService.get(
        'cities',
        `?itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}&order[name]=${this.nameOrder.query}`
      )
      .then(({ data }) => {
        this.cities = [...data['hydra:member']];
        this.pagination.totalItems = data['hydra:totalItems'];
        this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage);
      })
      .catch((error) => {
        console.error('Error fetching cities:', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    deleteEntity(id) {
      if (!confirm('Do you want to delete this city?')) return;

      this.isLoading = false;
      ApiService.delete(`cities/${id}`)
        .then(() => this.getCities())
        .finally(() => {
          this.isLoading = true;
        });
    },
    toggleViewCitiesModal(key) {
      this.selectedLocations = this.cities[key];
      this.$root.$emit('bv::show::modal', 'city_locations');
    },
  },
};
</script>

